/* ============
 * Mutations for the account module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { ALL, CONFIG, USER, DETAILS, DEFAULT_BILLING, DEFAULT_SHIPPING, ADDRESS_BOOK, TOGGLE_DRAWER } from "./mutation-types";

import type {Address} from '../../../models/address.model'

/* eslint-disable no-param-reassign */
export default {
    [CONFIG](state: any, config: any) {
        state.config = config;
    },
    [USER](state: any, user: any) {
        state.user = user;
    },
    [DETAILS](state: any, details: any) {
        state.details = details;
        //localStorage.setItem('user-details', JSON.stringify(details));
    },
    [DEFAULT_BILLING](state: any, defaultBilling: any) {
        state.billingDetails = defaultBilling;
    },
    [DEFAULT_SHIPPING](state: any, defaultShipping: any) {
        state.shippingDetails = defaultShipping;
    },
    [ADDRESS_BOOK](state: any, addressBook: Array<Address>){
        state.addressBook = addressBook;
        //localStorage.setItem('user-addressbook', JSON.stringify(addressBook));
    },
    [TOGGLE_DRAWER](state: any) {
        state.drawerOpen = !state.drawerOpen;
    },
    async "initialiseStore"(state: any) {
        state.details = JSON.parse(localStorage.getItem('user-details'));
        state.addressBook = JSON.parse(localStorage.getItem('user-addressbook'));

        return Promise.resolve();
    },
};
