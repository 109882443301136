import Vue from 'vue';
import Vuetify from 'vuetify';

//import colors from 'vuetify/es5/util/colors';
import '../assets/styles/custom.scss'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const opts = {theme: {
        themes: {
            light: {
                primary: '#005fa2',
                secondary: '#F5F5F5',
                accent: '#3AE3D6',
                error: '#FF5252',
                info: '#2d3753',
                success: '#4CAF50',
                warning: '#f78012',
                white: '#FFFFFF'
            }
        },
        theme: {
            options: {
                customProperties: true,
            },
            dark: false,
        },
        icons: {
            iconfont: 'material-icons',
        },
    },
};

export default new Vuetify(opts)
