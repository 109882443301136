// Define enum for quantityAvailable
export enum QuantityAvailability {
    NOT_AVAILABLE = "NOT_AVAILABLE",
    LIMITED_AVAILABILITY = "LIMITED_AVAILABILITY",
    AVAILABLE = "AVAILABLE"
}

/**
 * @interface Package
 * Defines the properties of a package
 */
export interface Package {
    /**
     * The identifier for the type of package
     */
    packagetypeid: number | null;
    /**
     * The name of the package
     */
    packagetypename: string | null;
    /**
     * The weight of the package
     */
    packageweight: number | null;
    /**
     * The width of the package
     */
    packagewidth: number | null;
    /**
     * The length of the package
     */
    packagelength: number | null;
    /**
     * The height of the package
     */
    packageheight: number | null;
    /**
     * The class of the package
     */
    packageclass: string | null;
    /**
     * The identifier for the class of the package
     */
    packageclassid: number | null;
    /**
     * A description of the package
     */
    packagedescription: string | null;
    /**
     * The identifier for the dangerous goods class of the package
     */
    packagedangerousgoodsclassid: number | null;
}

/**
 * @interface Product
 * Defines the properties of a product
 */
export interface Product {
    /**
     * The number indicating the row of the product in the list
     */
    rownumber: number;
    /**
     * The unit of measure for sale of the product
     */
    saleuom: string;
    /**
     * The number of units of the product available for sale
     */
    quantityavailable: number;
    /**
     * The minimum number of units required to be purchased
     */
    minimumquantity: number | null;
    /**
     * The identifier for the product
     */
    id: number;
    /**
     * The item code for the product
     */
    item: string;
    /**
     * A description of the product
     */
    description: string;
    /**
     * The name of the product to be displayed
     */
    displayname: string;
    /**
     * The identifier for the category of the product
     */
    categoryid: number;
    /**
     * The price for the customer to purchase the product
     */
    price: number | null;
    /**
     * The current lead time4
     */
    leadtime: number | null;
    /**
     * The identifier for the type of package
     */
    packagetypeid: number | null;
    /**
     * The name of the package
     */
    packagetypename: string | null;
    /**
     * The weight of the package
     */
    packageweight: number | null;
    /**
     * The width of the package
     */
    packagewidth: number | null;
    /**
     * The length of the package
     */
    packagelength: number | null;
    /**
     * The height of the package
     */
    packageheight: number | null;
    /**
     * The class of the package
     */
    packageclass: string | null;
    /**
     * The identifier for the class of the package
     */
    packageclassid: number | null;
    /**
     * A description of the package
     */
    packagedescription: string | null;
    /**
     * The identifier for the dangerous goods class of the package
     */
    packagedangerousgoodsclassid: number | null;
    /**
     * A flag to determine if the item is in the customer catalogue
     */
    isInCustomerCatalogue: boolean | null;
    isconfigurable: boolean | string;
    configurationtype: number | null;
}

/**
 * @interface TransformedProduct
 * Defines the transformed properties of a product for presentation purposes
 */
export interface TransformedProduct {
    /**
     * The identifier for the product
     */
    id: number;
    /**
     * The item code for the product
     */
    itemcode: string;
    /**
     * The name of the product
     */
    name: string;
    /**
     * A description of the product
     */
    description: string;
    /**
     * The price for the customer to purchase the product
     */
    price: number | null;
    /**
     * The current lead time in weeks
     */
    leadtimeinweeks: number | null;
    /**
     * The minimum number of units required to be purchased
     */
    minimumquantity: number | null;
    /**
     * The unit of measure for sale of the product
     */
    saleuom: string;
    /**
     * The number of units of the product available for sale
     */
    quantityavailable: QuantityAvailability;
    /**
     * The current stock level if available
     */
    stocklevel: number;
    /**
     * The package information for the product
     */
    itempackage: Package;
    /**
     * A flag to determine if the item is in the customer catalogue
     */
    isincustomercatalogue: boolean | null;
    isconfigurable: boolean | string;
    configurationtype: number | null;
}

/**
 * @interface ProductList
 * Defines the properties of a product list
 */
export interface ProductList {
    /**
     * An array of products
     */
    data: Product[];
}
