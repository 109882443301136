/* ============
 * Account Transformer
 * ============
 *
 * The transformer for the account.
 */

import Transformer from './transformer';

import type { Product, TransformedProduct } from "../models/product.model";
import { QuantityAvailability } from "../models/product.model";

export default class ProductTransformer extends Transformer {
    /**
     * Method used to transform a product for presentation purposes.
     *
     *
     * @returns {TransformedProduct} The transformed product.
     * @param {Product} product the product data fetched from the API call.
     */
    static fetch(product: Product): TransformedProduct {
        console.log(product);

        const {
            id,
            item: itemcode,
            displayname: name,
            description,
            price,
            leadtime,
            minimumquantity,
            saleuom,
            quantityavailable,
            packagetypeid,
            packagetypename,
            packageweight,
            packagewidth,
            packagelength,
            packageheight,
            packageclass,
            packageclassid,
            packagedescription,
            packagedangerousgoodsclassid,
            isInCustomerCatalogue,
            isconfigurable,
            configurationtype
        } = product;

        // Map quantityAvailable to enum values
        let transformedQuantityAvailable: QuantityAvailability;
        if (quantityavailable >= 5) {
            transformedQuantityAvailable = QuantityAvailability.AVAILABLE;
        } else if (quantityavailable < 5 && quantityavailable > 0) {
            transformedQuantityAvailable = QuantityAvailability.LIMITED_AVAILABILITY;
        } else {
            transformedQuantityAvailable = QuantityAvailability.NOT_AVAILABLE;
        }

        // Calculate lead time in weeks
        const leadtimeinweeks = leadtime ? Math.ceil(leadtime / 7) : null;

        // Set default minimum quantity to 1 if not provided
        const transformedMinimumQuantity = minimumquantity || 1;

        let transformedIsConfigurable = false;
        if (typeof(isconfigurable) == 'string') {
            transformedIsConfigurable = isconfigurable === 'T';
        } else {
            transformedIsConfigurable = isconfigurable;
        }

        // construct the item package
        const itempackage = {
            packagetypeid,
            packagetypename,
            packageweight,
            packagewidth,
            packagelength,
            packageheight,
            packageclass,
            packageclassid,
            packagedescription,
            packagedangerousgoodsclassid
        }

        return {
            id,
            itemcode,
            name,
            description,
            price,
            leadtimeinweeks,
            minimumquantity: transformedMinimumQuantity,
            saleuom,
            quantityavailable: transformedQuantityAvailable,
            stocklevel: quantityavailable,
            itempackage,
            isincustomercatalogue: isInCustomerCatalogue,
            isconfigurable: transformedIsConfigurable,
            configurationtype
        };
    }
}
