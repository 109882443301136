import * as types from './mutation-types';
import store from "../../index";
import AuthProxy from "../../../proxies/auth.proxy";
import Vue from "vue";

export const check = ({ commit }) => {
    commit(types.CHECK);
};

export const login = async ({ commit }, payload: { email, password }) => {
    return await new AuthProxy()
        .login(payload)
        .then((response: any) => {
            if (!response.token) {
                return Promise.reject({
                    message: 'Unable to authenticate login. Please try again later.'
                });
            }

            commit(types.LOGIN, response.token);

            // get global user details and configuration information.
            store.dispatch('user/all').then(() => {
                // @ts-ignore
                Vue.router.push({
                  path: '/dashboard',
                });

                return Promise.resolve();
            }).catch(() => {
                return Promise.reject({
                    message: 'An unknown error has occurred.'
                });
            })
        })
        .catch(error => {
            return Promise.reject({
                message: error.message || 'An unknown error has occurred.'
            });
        });
};

export const activate = async ({ commit }, payload: { email, password, apiToken }) => {
    return await new AuthProxy()
        .activate(payload)
        .then((response: any) => {
            // @ts-ignore
            Vue.router.push({
                path: '/activate-success',
            });

            return Promise.resolve();
        })
        .catch(error => {
            return Promise.reject({
                message: error.message || 'An unknown error has occurred.'
            });
        });
}

export const logout = ({ commit }, payload: {isError: false}) => {
    commit(types.LOGOUT, payload.isError);

    // @ts-ignore
    Vue.router.push({
        path: '/login',
    });
};

export default {
    check,
    login,
    logout,
    activate
}
